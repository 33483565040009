import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Switch, useRouteMatch } from 'react-router-dom'
import { handlerGetProjects } from '../../store/scope/action'
import { handlerGetUserInfo } from '../../store/user/actions'
import { PrivateRoute } from '../../App'
import CreateRoom from './CreateRoom'
import CreateProject from './CreateProject'
import EditRoom from './EditRoom'
import ProjectScreen from './ProjectScreen'
import ProjectReport from './ProjectReport'
import ProjectEditReport from './ProjectEditReport'
import ProjectList from './ProjectList'
import CreateUser from './CreateUser'
import SheetsAdmin from './SheetsAdmin'
import ListUser from './ListUser'
import EditProject from './EditProject'

function TheScope() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()

  useEffect(() => {
    dispatch(handlerGetUserInfo({ history }))
    dispatch(handlerGetProjects())
  }, [])

  return (
    <Switch>
      <PrivateRoute path="/painel/list-users" exact component={ListUser} />
      <PrivateRoute path="/painel/create-user" exact component={CreateUser} />
      <PrivateRoute
        path="/painel/project/:id/:name/create-room"
        exact
        component={CreateRoom}
      />
      <PrivateRoute
        path="/painel/project/:id/:name/edit-room/:idRoom"
        exact
        component={EditRoom}
      />
      <PrivateRoute
        path="/painel/project/:id/:name/report"
        exact
        component={ProjectReport}
      />
      <PrivateRoute
        path="/painel/project/:id/:name/report/edit"
        exact
        component={ProjectEditReport}
      />
      <PrivateRoute
        path="/painel/project/:id/:name"
        exact
        component={ProjectScreen}
      />
      <PrivateRoute
        path="/painel/project/:id/:name/edit"
        exact
        component={EditProject}
      />
      <PrivateRoute
        path="/painel/create-project"
        exact
        component={CreateProject}
      />
      <PrivateRoute path="/painel/edit-sheet" exact component={SheetsAdmin} />
      <PrivateRoute path={path} exact component={ProjectList} />
    </Switch>
  )
}

export default TheScope
