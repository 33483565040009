import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import FolderIcon from '@material-ui/icons/Folder'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentIcon from '@material-ui/icons/Assignment'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useHistory, useLocation } from 'react-router-dom'
import Moment from 'react-moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { handlerDeleteProject } from '../../store/scope/action'
import NavBar from '../../components/NavBar'

const useStyles = makeStyles(theme => ({
  button: {},
  divInputs: {
    margin: '1.5rem 0'
  },
  divH1: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonPainel: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 18
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  divFeedback: {
    display: 'flex',
    justifyContent: 'center',
    color: 'darkgreen',
    fontWeight: 'bold',
    border: '1px solid darkgreen',
    borderRadius: 4,
    padding: 10
  }
}))

function ProjectList() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const result: any = useSelector((state: any) => state)
  const [open, setOpen] = React.useState(false)
  const [idProject, setIdProject] = React.useState('')
  const location: any = useLocation()
  let userSuccess = false
  if (location.state) {
    userSuccess = true
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const confirmDelete = () => {
    dispatch(
      handlerDeleteProject({
        idProject
      })
    )
    handleClose()
  }

  return (
    <>
      <NavBar />
      <Container fixed>
        <Grid style={{ marginTop: 30 }}>
          <div className={classes.divInputs}>
            <Button
              className={classes.buttonPainel}
              size="large"
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push('/painel/create-project')
              }}
            >
              CREATE A NEW PROJECT
            </Button>
          </div>
          {userSuccess && (
            <div className={classes.divFeedback}>User created successfully</div>
          )}
          <div className={classes.divInputs}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" className={classes.title}>
                List of Projects
              </Typography>
              <div className={classes.demo}>
                <List>
                  {result.scope.projects.data.map((a: any) => (
                    <ListItem key={a._id}>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={a.costumer}
                        secondary={
                          <Moment format="YYYY-MM-DD, hh:mm:ss A">
                            {a.date_job}
                          </Moment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            history.push(
                              `/painel/project/${a._id}/${a.slug_url}/report/edit`
                            )
                          }}
                          edge="end"
                          aria-label="Edit"
                        >
                          <AssignmentIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            history.push(
                              `/painel/project/${a._id}/${a.slug_url}/report`
                            )
                          }}
                          edge="end"
                          aria-label="Edit"
                        >
                          <PictureAsPdfIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            history.push(
                              `/painel/project/${a._id}/${a.slug_url}`
                            )
                          }}
                          edge="end"
                          aria-label="Edit"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setIdProject(a._id)
                            handleClickOpen()
                          }}
                          edge="end"
                          aria-label="Delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
          </div>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Do you really want to delete this project?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Disagree
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
}

export default ProjectList
