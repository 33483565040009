import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SaveIcon from '@material-ui/icons/Save'
import { Grid, Button, TextField } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import MeetingRoom from '@material-ui/icons/MeetingRoom'
import Divider from '@material-ui/core/Divider'
import HomeIcon from '@material-ui/icons/Home'
import { useSelector } from 'react-redux'
import Moment from 'react-moment'
import Modal from '@material-ui/core/Modal'
import NavBar from '../../components/NavBar'
import CreateRoomModal from './CreateRoomModal'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(3),
      backgroundColor: '#f0f0f0'
    },
    divInputs: {
      margin: '1rem 0'
    },
    divInputsBg: {
      padding: '1rem',
      backgroundColor: '#f0f0f0'
    },
    formControl: {
      margin: '0.2rem 0',
      backgroundColor: '#f0f0f0'
    },
    buttonSave: {
      position: 'fixed',
      zIndex: 10,
      borderRadius: 0,
      bottom: 0,
      padding: 20
    },
    buttonFab: {
      position: 'fixed',
      zIndex: 10,
      bottom: 80,
      right: 20
    },
    gridPadding: {
      paddingBottom: 70
    },
    listItem: {
      fontSize: '0.9rem'
    },
    paddingLeft: {
      paddingLeft: 10
    },
    marginList: {
      margin: '10px 0'
    },
    paper: {
      width: '80%',
      margin: 'auto',
      marginTop: '30%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      position: 'relative'
    }
  })
)

export default function ProjectScreen() {
  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const { id, name } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const result: any = useSelector((state: any) => state)
  let project: any = { crew_chief: { name: '' }, rooms: [] }
  result.scope.projects.data.forEach((p: any) => {
    if (p._id === id) {
      project = p
    }
  })
  const saveProject = () => {
    history.push('/painel')
  }

  const addRoom = () => {
    handleOpenModal()
  }

  return (
    <>
      <NavBar />
      <div className={classes.divInputsBg}>
        <Button
          onClick={() => {
            history.push(`/painel/project/${id}/${name}/edit`)
          }}
          color="primary"
          startIcon={<Icon>edit</Icon>}
        >
          Edit Project
        </Button>
        <div className={classes.marginList}>
          <strong>Costumer:</strong>
          <span className={classes.paddingLeft}>{project.costumer}</span>
        </div>
        <Divider />
        <div className={classes.marginList}>
          <strong>Date:</strong>
          <span className={classes.paddingLeft}>
            <Moment format="YYYY-MM-DD">{project.date_job}</Moment>
          </span>
        </div>
        <Divider />
        <div className={classes.marginList}>
          <strong>Time:</strong>
          <span className={classes.paddingLeft}>
            <Moment format="hh:mm:ss A">{project.time_job}</Moment>
          </span>
        </div>
        <Divider />
        <div className={classes.marginList}>
          <strong>Tech Lead:</strong>
          <span className={classes.paddingLeft}>
            {project.lead_tech ? project.lead_tech : result.lead_tech}
          </span>
        </div>
        <Divider />
        <div className={classes.marginList}>
          <strong>Project Manager:</strong>
          <span className={classes.paddingLeft}>
            {project?.crew_chief?.name
              ? project.crew_chief.name
              : result.user.name}
          </span>
        </div>
      </div>
      <List component="div">
        <ListItem>
          <MeetingRoom />
          <ListItemText primary="Rooms List" />
        </ListItem>
      </List>
      <Divider />
      <List component="div">
        {project.rooms.map((a: any, key: number) => (
          <ListItem
            onClick={() => {
              history.push({
                pathname: `/painel/project/${id}/${name}/edit-room/${a._id}`,
                state: { room: a }
              })
            }}
            button
            key={a._id || key}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={a.name || a.name_room} />
          </ListItem>
        ))}
      </List>
      <Grid className={classes.gridPadding}>
        <Fab
          onClick={() => {
            addRoom()
          }}
          className={classes.buttonFab}
          color="secondary"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          className={classes.buttonSave}
          startIcon={<SaveIcon />}
          onClick={() => {
            saveProject()
          }}
        >
          SAVE PROJECT
        </Button>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <CreateRoomModal handleCloseModal={handleCloseModal} />
          </div>
        </Modal>
      </Grid>
    </>
  )
}
