import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Grid, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import Moment from 'react-moment'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import NavBar from '../../components/NavBar'
import { urlApi, config } from '../../utils/configs'
import qs from 'qs'
import axios from 'axios'
import { logoDrytech } from '../../utils/imgsBase64'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function generatePdf(project: any) {
  const doc = new jsPDF()

  doc.setTextColor(100)
  doc.setDrawColor(10, 62, 143)
  doc.setFontSize(12)

  const { pageSize } = doc.internal
  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
  doc.addImage(logoDrytech, 'PNG', 14, 20, 40, 20)
  const costumer = doc.splitTextToSize(
    `Costumer: ${project.costumer}`,
    pageWidth - 35,
    {}
  )
  doc.text(costumer, 60, 15)
  doc.line(60, 18, pageWidth - 14, 18)

  const date = doc.splitTextToSize(
    `Date: ${moment(project.date_job).format('YYYY-MM-DD')}`,
    pageWidth - 35,
    {}
  )
  doc.text(date, 60, 25)
  doc.line(60, 28, pageWidth - 14, 28)

  const time = doc.splitTextToSize(
    `Time: ${moment(project.time_job).format('hh:mm:ss A')}`,
    pageWidth - 35,
    {}
  )
  doc.text(time, 60, 35)
  doc.line(60, 38, pageWidth - 14, 38)

  const leadtech = doc.splitTextToSize(
    `Lead Tech: ${project.lead_tech}`,
    pageWidth - 35,
    {}
  )
  doc.text(leadtech, 60, 45)
  doc.line(60, 48, pageWidth - 14, 48)

  const crew = doc.splitTextToSize(
    `Project Manager: ${project.crew_chief.name}`,
    pageWidth - 35,
    {}
  )
  doc.text(crew, 60, 55)
  let tableHeight: any = {}
  let startLoop = true

  project.rooms.forEach((r: any, key: any) => {
    let nameCat = ''
    const count = key + 1
    const { category, class_room, name_room, hours_type, note_room } = r
    const linhasRoom: any = []
    linhasRoom.push([
      `Category: ${category}`,
      `Class: ${class_room}`,
      `Hours: ${hours_type}`,
      `Notes: ${note_room}`
    ])

    if (count === 1) {
      autoTable(doc, {
        head: [
          [
            {
              content: name_room,
              colSpan: 4,
              styles: { halign: 'center', fillColor: [19, 15, 64] }
            }
          ]
        ],
        startY: 60,
        body: linhasRoom,
        didDrawPage: (HookData: any) => {
          tableHeight = HookData
        }
      })
    } else {
      autoTable(doc, {
        head: [
          [
            {
              content: name_room,
              colSpan: 3,
              styles: { halign: 'center', fillColor: [19, 15, 64] }
            }
          ]
        ],
        body: linhasRoom,
        didDrawPage: (HookData: any) => {
          tableHeight = HookData
        }
      })
    }

    r.items.forEach((i: any) => {
      const linhasTabela: any = []
      nameCat = i.name
      i.items.forEach((ii: any) => {
        const { cod, text, pdf } = ii
        if (pdf !== 'default') {
          const textCalc = text.split('#')
          if (textCalc.length >= 2) {
            const textFinal = parseFloat(textCalc[0]) * parseFloat(textCalc[1])
            linhasTabela.push([cod, textFinal])
          }
          if (textCalc.length === 1) {
            const pdfMult = pdf.replace('*', '')
            const textFinal = parseFloat(text) * parseFloat(pdfMult)
            linhasTabela.push([cod, textFinal])
          }
        } else {
          linhasTabela.push([cod, text])
        }
      })
      if (linhasTabela.length === 0) {
        // linhasTabela.push(['no items registered'])
      } else {
        autoTable(doc, {
          head: [
            [
              {
                content: nameCat,
                colSpan: 2,
                styles: { halign: 'center', fillColor: [10, 62, 143] }
              }
            ]
          ],
          body: linhasTabela,
          didDrawPage: (HookData: any) => {
            tableHeight = HookData
          }
        })
        startLoop = false
      }
    })
  })

  // doc.text('text', 14, tableHeight.table.finalY + 10)

  return doc
}

function savePdf(project: any) {
  const doc = generatePdf(project)
  doc.save(project.costumer)
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  divInputs: {
    margin: '1.5rem 0'
  },
  divH1: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonPainel: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 18
  },
  divInputsBg: {
    padding: '1rem',
    backgroundColor: '#f0f0f0'
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  table: {
    minWidth: 650
  },
  gridPadding: {
    paddingBottom: 70
  },
  listItem: {
    fontSize: '0.9rem'
  },
  paddingLeft: {
    paddingLeft: 10
  },
  marginList: {
    margin: '10px 0'
  },
  divCatList: {
    textAlign: 'center',
    padding: 15,
    fontWeight: 'bold',
    backgroundColor: '#C2C2C2' // '#f0f0f0'
  },
  divItemList: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    fontWeight: 500
  }
}))

function ProjectReport() {
  const { id, name } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const projectStart: any = { crew_chief: { name: '' }, rooms: [] }
  const [project, setProject] = useState(projectStart)

  useEffect(() => {
    axios
      .get(`${urlApi}/projects/getProject`, {
        withCredentials: true,
        params: {
          id
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          setProject(res.data.data)
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }, [])

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  console.log(project)

  return (
    <>
      <NavBar />
      <Container fixed>
        <Grid style={{ marginTop: 30 }}>
          <h1>Project Report</h1>
          <Button
            onClick={() => {
              savePdf(project)
            }}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            Generate PDF
          </Button>
          <div className={classes.divInputsBg}>
            <div className={classes.marginList}>
              <strong>Costumer:</strong>
              <span className={classes.paddingLeft}>{project.costumer}</span>
            </div>
            <Divider />
            <div className={classes.marginList}>
              <strong>Date:</strong>
              <span className={classes.paddingLeft}>
                <Moment format="YYYY-MM-DD">{project.date_job}</Moment>
              </span>
            </div>
            <Divider />
            <div className={classes.marginList}>
              <strong>Time:</strong>
              <span className={classes.paddingLeft}>
                <Moment format="hh:mm:ss A">{project.time_job}</Moment>
              </span>
            </div>
            <Divider />
            <div className={classes.marginList}>
              <strong>Lead Tech:</strong>
              <span className={classes.paddingLeft}>{project.lead_tech}</span>
            </div>
            <Divider />
            <div className={classes.marginList}>
              <strong>Project Manager:</strong>
              <span className={classes.paddingLeft}>
                {project?.crew_chief?.name || ''}
              </span>
            </div>
          </div>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {project.rooms.map((r: any, key: number) => (
                <Tab key={r._id} label={r.name_room} {...a11yProps(key)} />
              ))}
            </Tabs>
          </AppBar>
          {project.rooms.map((r: any, key: number) => (
            <TabPanel key={r._id} value={value} index={key}>
              <div
                style={{
                  width: '100%',
                  border: '1px solid #C2C2C2',
                  padding: 8
                }}
              >
                <span>
                  <strong>Category:</strong> {r.category}
                </span>
                <span style={{ marginLeft: 10 }}>
                  <strong>Class Room:</strong> {r.class_room}
                </span>
                <div style={{marginTop: 10, whiteSpace: 'pre-line'}}>
                  <strong>Room Notes:</strong> {r.note_room}
                </div>
              </div>
              {r.items.map((i: any) => (
                <div key={i._id}>
                  <div>
                    <div className={classes.divCatList}>{i.name}</div>
                    {i.items.length === 0 && (
                      <div className={classes.divItemList}>
                        <div
                          style={{
                            width: '100%',
                            border: '1px solid #C2C2C2',
                            textAlign: 'center',
                            padding: 5
                          }}
                        >
                          no items registered
                        </div>
                      </div>
                    )}
                    {i.items.map((ii: any) => (
                      <div className={classes.divItemList} key={ii._id}>
                        <div
                          style={{
                            width: '30%',
                            border: '1px solid #C2C2C2',
                            padding: 8
                          }}
                        >
                          {ii.cod}
                        </div>
                        <div
                          style={{
                            width: '30%',
                            border: '1px solid #C2C2C2',
                            padding: 8
                          }}
                        >
                          {ii.name}
                        </div>
                        <div
                          style={{
                            flex: '1',
                            border: '1px solid #C2C2C2',
                            padding: 8
                          }}
                        >
                          {ii.text}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </TabPanel>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default ProjectReport
